<template>
  <en-dialog :model-value="modelValue" title="单据历史" center @close="$emit('update:model-value', false)" width="50%">
    <en-collapse>
      <en-collapse-item title="单据基础信息">
        <div>
          <span class="p-2">单据编号 :{{ data?.serialNo }}</span>
        
        </div>
      </en-collapse-item>
      <en-collapse-item title="单据审核信息">
        <span>备注:{{ data?.comment }}</span>
        <div>单据历史：
    <div v-for="item in data?.logs" class="p-1">
    <span class="pl-15 ">{{ item.operator.name }}</span>
    <span class="p-3 ">{{ item. message}}</span>
    <span class="p-2 ">{{ formatDate(item.datetime )}}</span>
  </div>
  </div>
      </en-collapse-item>
    </en-collapse>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudInventoryDefinitions['SaleDto' | 'StockTransferOutDto']>,
    code: {
 
      default: ''
    }
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  }
})
</script>
